 // 获取保险产品列表
import get_insurance_product_list from "@/lib/data-service/yinzhilv-temporary-storage-system/get_insurance_product_list";
 // 获取保险产品类型枚举
import get_insurance_enumeration from "@/lib/data-service/yinzhilv-temporary-storage-system/get_insurance_enumeration";
 // 根据key值取appConfig的内容
import get_app_config from '@/lib/data-service/yinzhilv-temporary-storage-system/get_app_config';
export default {
  data() {
    return {
      emptyType: "search",

      loading: false,
      typeCode: "",
      tabList: [],

      list: [],
    };
  },
  watch: {
    typeCode(typeCode) {
      this.loading = true;
      this.req_list();
    },
  },
  methods: {
    init() {
      this.loading = true;
      get_insurance_enumeration()
        .then((res) => {
          const list = res.data.allInsuranceProductTypes;
          this.tabList = list;
          this.typeCode = list[0].code;
        })
        .catch(() => {
          this.emptyType = "network";
          this.loading = false;
        });
    },
    req_list() {
      this.list = [];
      this.emptyType = "";
      get_insurance_product_list({ typeCode: this.typeCode })
        .then((result) => {
          this.list = result.data.allInsuranceProducts;
          if (!this.list.list) this.emptyType = "search";
          this.loading = false;
        })
        .catch((err) => {
          this.emptyType = "network";
          this.loading = false;
        });
    },

    search() {
      get_app_config({key: 'yinzhilv_insurance_search'}).then((result) => {
        window.open(result.data.allAppConfigs[0].value, '_blank');
      }).catch((err) => {
        
      });
    },

    change_tab(val) {
      this.typeCode = val;
    },
  },
  mounted() {
    this.init();
  },
};
